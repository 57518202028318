import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {

  render() {
    return (
      <Layout>
        <Helmet
          title="旅行"
          meta={[
            { name: 'description', content: '旅行のしおりです。' },
            { name: 'keywords', content: '' },
            { name: 'robots', content: 'noindex' },
          ]}
        >
        </Helmet>

          {
        //<Banner />
          }

        <div id="main">
          {
          //<section id="one" className="tiles">
          //  <article style={{backgroundImage: `url(${pic05})`}}>
          //    <header className="major">
          //      <h3>宇治</h3>
          //      <p>宇治市は京都の南に位置し、寺社仏閣で有名です。10 世紀に建てられた仏教寺院の平等院には、鳳凰を冠する鳳凰堂があります。宇治上神社は、日本最古の神社と言われています。源氏物語ミュージアムは、源氏物語に出てくる場面などが紹介されている博物館です。また、宇治市は緑茶の生産地として知られ、対鳳庵では伝統的な茶の湯を体験できます。</p>
          //    </header>
          //  </article>
          //  <article style={{backgroundImage: `url(${pic01})`}}>
          //    <header className="major">
          //      <h3>平等院鳳凰堂</h3>
          //      <p>平等院は、日本の近畿地方の、京都府宇治市宇治蓮華に所在する、藤原氏ゆかりの仏教寺院。</p>
          //    </header>
          //  </article>
          //  <article style={{backgroundImage: `url(${pic06})`}}>
          //    <header className="major">
          //      <h3>嵐山</h3>
          //      <p>嵐山は京都府京都市の観光地。国の史跡および名勝に指定されている。 本来地名としては西京区を指し、左岸は右京区嵯峨であるが、観光案内等では嵯峨地区を含めた渡月橋周辺全域を一まとめに嵐山と称することが多いため、ここでは渡月橋周辺全域としての嵐山を扱う。</p>
          //    </header>
          //  </article>
          //  <article style={{backgroundImage: `url(${pic02})`}}>
          //    <header className="major">
          //      <h3>金閣寺</h3>
          //      <p>鹿苑寺は、京都市北区にある臨済宗相国寺派大本山相国寺の境外塔頭。建物の内外に金箔を貼った3層の楼閣建築である舎利殿は金閣、舎利殿を含めた寺院全体は金閣寺として知られる。 寺名は開基である足利義満の法号・鹿苑院殿にちなむ。山号は北山。寺紋は五七桐。義満の北山山荘をその死後に寺としたものである。</p>
          //    </header>
          //  </article>
          //  <article style={{backgroundImage: `url(${pic03})`}}>
          //    <header className="major">
          //      <h3>銀閣寺</h3>
          //      <p>慈照寺は、京都府京都市左京区銀閣寺町にある、臨済宗相国寺派の仏教寺院。相国寺の境外塔頭である。室町時代後期に栄えた東山文化を代表する建築と庭園を有する。 室町幕府第8代将軍足利義政が鹿苑寺の舎利殿を模して造営した楼閣建築である観音殿は銀閣、観音殿を含めた寺院全体は銀閣寺として知られる。</p>
          //    </header>
          //  </article>
          //  <article style={{backgroundImage: `url(${pic04})`}}>
          //    <header className="major">
          //      <h3>清水寺</h3>
          //      <p>清水寺は、京都府京都市東山区清水にある寺院。山号は音羽山。もとは法相宗に属したが、現在は独立して北法相宗大本山を名乗る。西国三十三所第16番札所。本尊は十一面千手観世音菩薩。 本尊真言：おん ばざら たらま きりく そわか ご詠歌：松風や音羽の滝の清水を むすぶ心は涼しかるらん</p>
          //    </header>
          //  </article>
          //</section>
          }

          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>4日(金)</h2>
              </header>
              <h3>仕事</h3>
              <ul>
                <li>[19:30]伊丹空港到着</li>
                <li>[20:30]梅田お好み焼き</li>
                <li>[22:00]帰宅</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>5日(土)</h2>
              </header>
              <h3>休暇</h3>
              <ul>
                <li>[11:30]ホテルランチ</li>
                <li>[14:00]アメ村</li>
                <li>[18:00]トリキ</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>6日(日)</h2>
              </header>
              <h3>休暇</h3>
              <ul>
                <li>[11:00]天神橋筋</li>
                <li>[16:00]梅田</li>
                <li>[18:00]タコパ</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>7日(月)</h2>
              </header>
              <h3>仕事</h3>
              <ul>
                <li>お留守番</li>
                <li>[18:30]鍋</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>8日(火)</h2>
              </header>
              <h3>仕事（在宅）</h3>
              <ul>
                <li>[12:00]ラーメン</li>
                <li>[18:00]焼肉</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>9日(水)</h2>
              </header>
              <h3>仕事（在宅）</h3>
              <ul>
                <li>[12:00]ラーメン</li>
                <li>[18:00]お好み焼き</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>10日(木)</h2>
              </header>
              <h3>仕事</h3>
              <ul>
                <li>お留守番</li>
                <li>[18:30]鍋</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>11日(金)</h2>
              </header>
              <h3>仕事</h3>
              <ul>
                <li>お留守番</li>
                <li>[15:40]空港到着</li>
                <li>[19:00]ホテル到着</li>
                <li>[19:30]トリキ</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>12日(土)</h2>
              </header>
              <h3>休暇</h3>
              <ul>
                <li>[10:00]チェックアウト（キャリー３つ預ける）</li>
                <li>[12:00]天王寺会食</li>
                <li>[15:00]新世界</li>
                <li>[19:00]ホテル チェックイン</li>
                <li>[20:00]京都散策、ラーメン</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>13日(日)</h2>
              </header>
              <h3>休暇</h3>
              <ul>
                <li>[08:00]朝食</li>
                <li>[12:00]七条周辺</li>
                <li>[19:00]ホテル ディナー</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>14日(月)</h2>
              </header>
              <h3>休暇</h3>
              <ul>
                <li>[08:30]チェックアウト</li>
                <li>[09:00]朝食</li>
                <li>[10:00]清水通り</li>
                <li>[12:00]そば屋</li>
                <li>[14:00]清水寺</li>
                <li>[19:00]ホテル到着</li>
              </ul>
            </div>
            <div className="inner">
              <header className="major">
                <h2>15日(火)</h2>
              </header>
              <h3>仕事</h3>
              <ul>
                <li>[12:25]空港出発</li>
              </ul>
            </div>
            {
              /* コピーすること
            <div className="inner">
              <header className="major">
                <h2>2日目</h2>
              </header>
              <ul>
                <li>[09:00]知恩院 モーニング</li>
                <li>[10:30]知恩院 写経</li>
                <li>[12:00]知恩院 出発</li>
                <li>[13:30]平安神宮 周辺</li>
                <li>[16:30]知恩院 到着</li>
                <li>[17:30]ギャラリー 到着</li>
                <li>[18:00]ギャラリー ディナー</li>
              </ul>
              <h3>買い物</h3>
              <ul>
                <li>平安神宮 御朱印帳</li>
              </ul>
            </div>
              */
            }
          </section>
        </div>

      </Layout>
    )
  }
}

export default HomeIndex
